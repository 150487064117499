var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header"},[_c('div',{attrs:{"id":"hamburger"},on:{"click":_vm.toggleMenu}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('span',{staticClass:"website-title"},[_vm._v(" mijn Mr illi ")])]),_c('v-navigation-drawer',{staticClass:"nav-drawer",attrs:{"app":"","left":"","dark":"","width":"300"},model:{value:(_vm.showNavigation),callback:function ($$v) {_vm.showNavigation=$$v},expression:"showNavigation"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","height":"100%"}},[_c('v-list',[(_vm.authenticated)?_c('v-list-item',{attrs:{"two-line":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticStyle:{"color":"rgba(255,255,255,0.5)"}},[_vm._v(_vm._s(_vm.$t('theNavigation.loggedInAs'))+":")]),_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.user.name))])])],1)],1):_vm._e(),(_vm.authenticated)?_c('v-divider'):_vm._e(),(_vm.authenticated)?_c('v-list-item',{attrs:{"link":"","to":{
                name: 'account'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.account'))+" ")])],1)],1):_vm._e(),(_vm.authenticated)?_c('v-list-item',{attrs:{"exact":"","link":"","to":{
                name: 'gekoppeldeApparaten'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-television")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.stream'))+" ")])],1)],1):_vm._e(),(_vm.authenticated && _vm.user.permissions.editGeneralDayprogram)?_c('v-list-item',{attrs:{"exact":"","link":"","to":{
                name: 'dayprogram'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-columns")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.dayprogram'))+" ")])],1)],1):_vm._e(),(_vm.authenticated && _vm.user.permissions.editTags)?_c('v-list-item',{attrs:{"exact":"","link":"","to":{
                name: 'tagSysteem'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tag")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.tagSystem'))+" ")])],1)],1):_vm._e(),(!_vm.authenticated)?_c('v-list-item',{attrs:{"link":"","to":{
                name: 'login'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.signIn'))+" ")])],1)],1):_vm._e(),(!_vm.authenticated)?_c('v-list-item',{attrs:{"link":"","to":{
                name: 'registreren'
            }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.register'))+" ")])],1)],1):_vm._e()],1),_c('div',{staticStyle:{"margin-top":"auto"}},[(_vm.authenticated)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.preventDefault();return _vm.signOut($event)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('theNavigation.signOut'))+" ")])],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }