// CHECK: Check if ENVIRONMENT, STREAM_VERSION and STREAM_VERSION_LAST_UPDATE are set correctly before deployment
const ENVIRONMENT = "live";

export const ILLI_STREAM_API_SERVERS = {live: "https://stream.illi-tv.nl", dev: "https://dev-stream.illi-tv.nl"};
const ILLI_STREAM_WS_SERVERS = {live: "https://stream.illi-tv.nl", dev: "https://dev-stream.illi-tv.nl"};
const ILLI_API_SERVERS = {live: "https://api.illi-engineering.nl", dev: "https://dev-api.illi-engineering.nl"};
const ILLI_AUTH_SERVERS = {live: "https://auth.illi-engineering.nl", dev: "https://dev-auth.illi-engineering.nl"};
const ILLI_PORTAL_SERVERS = {live: "https://mijn.mrilli.nl", dev: "https://dev-mijn.mrilli.nl"};
const ILLI_STREAM_DATA_SERVER_URLS = {live: "https://illi-stream.nl", dev: "https://dev.illi-stream.nl"};
const MR_ILLI_VIDEO_API_SERVERS = {live: "https://video-api.mrilli.nl", dev: "https://video-api.mrilli.nl"};


export const ILLI_STREAM_API_SERVER_URL = ILLI_STREAM_API_SERVERS[ENVIRONMENT];
export const ILLI_STREAM_WS_SERVER_URL = ILLI_STREAM_WS_SERVERS[ENVIRONMENT];
export const ILLI_API_SERVER_URL = ILLI_API_SERVERS[ENVIRONMENT];
export const ILLI_AUTH_SERVER_URL = ILLI_AUTH_SERVERS[ENVIRONMENT];
export const ILLI_PORTAL_SERVER_URL = ILLI_PORTAL_SERVERS[ENVIRONMENT];
export const MR_ILLI_VIDEO_API_SERVER_URL = MR_ILLI_VIDEO_API_SERVERS[ENVIRONMENT];

export const ILLI_STREAM_STATIC_URL = ILLI_STREAM_DATA_SERVER_URLS[ENVIRONMENT];

export const DAYPROGRAM_IMAGES_URL = `${ILLI_STREAM_STATIC_URL}/dagprogramma/`;
export const PHOTO_ALBUM_URL = `${ILLI_STREAM_STATIC_URL}/user/`;

export const THUMBNAIL_URL = {
    folder: `${ILLI_STREAM_STATIC_URL}/thumbnails/folder/`,
    video: `${ILLI_STREAM_STATIC_URL}/thumbnails/video/`,
};