import axios from "axios"
import { ILLI_API_SERVER_URL } from "../config"

export default {
    namespaced: true,
    state: {
        system: null,
    },

    getters: {
        system (state) {
            return state.system
        },
    },

    mutations: {
        SET_SYSTEM (state, system) {
            state.system = system
        }
    },
    actions: {
        async chooseSystem ({ commit }, system) {
            return commit('SET_SYSTEM', system)
        },

        async setSystem ({ commit }, systemid) {
            if(!systemid) return console.log("noid")
            try {
                let response = await axios.get(`${ILLI_API_SERVER_URL}/system/` + systemid)
                return commit('SET_SYSTEM', response.data.system[0])
            } catch (e) {
                return commit('SET_SYSTEM', null)
            }
        }
    }
}