const Home = () => import(/* webpackChunkName: "Home"*/ '../views/Home.vue');
const SignIn = () => import(/* webpackChunkName: "SignIn"*/ '../views/SignIn.vue');
const Interests = () => import(/* webpackChunkName: "Interests"*/ '../views/Interests.vue');
const Account = () => import(/* webpackChunkName: "Account"*/ '../views/Account.vue');
const Systems = () => import(/* webpackChunkName: "Systems"*/ '../views/Systems.vue');
const SystemMenu = () => import(/* webpackChunkName: "SystemMenu"*/ '../views/SystemMenu.vue');
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword"*/ '../views/ResetPassword.vue');
const ConfirmNewEmail = () => import(/* webpackChunkName: "ConfirmNewEmail"*/ '../views/ConfirmNewEmail.vue');
const Database = () => import(/* webpackChunkName: "Database"*/ '../views/database/Database.vue');
const Selected = () => import(/* webpackChunkName: "Selected"*/ '../views/database/Selected.vue');
const Residents = () => import(/* webpackChunkName: "Residents"*/ '../views/Residents.vue');
const Announcements = () => import(/* webpackChunkName: "Announcements"*/ '../views/Announcements.vue');
const manageResidents = () => import(/* webpackChunkName: "manageResidents"*/ '../views/manageResidents.vue');
const manageResidentsInfo = () => import(/* webpackChunkName: "manageResidentsInfo"*/ '../views/manageResidentsInfo.vue');
const manageResidentsFiles = () => import(/* webpackChunkName: "manageResidentsFiles"*/ '../views/manageResidentsFiles.vue');
const manageFacilityFiles = () => import(/* webpackChunkName: "manageFacilityFiles"*/ '../views/manageFacilityFiles.vue');
const manageLinkedDevice = () => import(/* webpackChunkName: "manageLinkedDevice"*/ '../views/manageLinkedDevice.vue');
const RequestPasswordReset = () => import(/* webpackChunkName: "RequestPasswordReset"*/ '../views/RequestPasswordReset.vue');
const UpdatePlatformMQTTPassword = () => import(/* webpackChunkName: "UpdatePlatformMQTTPassword"*/ '../views/UpdatePlatformMQTTPassword.vue');
const Api_key = () => import(/* webpackChunkName: "Api_key"*/ '../views/Api_key.vue');
const LinkDevice = () => import(/* webpackChunkName: "LinkDevice"*/ '../views/LinkDevice.vue');
const LinkedDevices = () => import(/* webpackChunkName: "LinkedDevices"*/ '../views/LinkedDevices.vue');
const privacyPolicy = () => import(/* webpackChunkName: "privacyPolicy"*/ '../views/PrivacyPolicy.vue');
const dayprogram = () => import(/* webpackChunkName: "dayprogram"*/ '../views/editDayprogram.vue');
const TagSystem = () => import(/* webpackChunkName: "TagSystem"*/ '../views/TagSystem.vue');
const Register = () => import(/* webpackChunkName: "Register"*/ '../views/Register.vue');
const ConfirmEmail = () => import(/* webpackChunkName: "ConfirmEmail"*/ '../views/ConfirmEmail.vue');
const ConfirmEmailAddPassword = () => import(/* webpackChunkName: "ConfirmEmailAddPassword"*/ '../views/ConfirmEmailAddPassword.vue');

const routes = [
    {
        path: '/login',
        name: 'login',
        component: SignIn
    }, {
        path: '/account',
        name: 'account',
        component: Account
    }, {
        path: '/database',
        name: 'database',
        component: Database,
        meta: { requiresAuth: true, requiresPermission: "editGeneralFiles" }
    }, {
        path: '/database-selectie',
        name: 'databaseSelectie',
        component: Selected
    }, {
        path: '/beheer-bewoners',
        name: 'beheerBewoners',
        component: manageResidents,
        meta: { requiresAuth: true, requiresPermission: "editResidents" }
    },{
        path: '/beheer-bewoners-info',
        name: 'beheerBewonersInfo',
        component: manageResidentsInfo,
        meta: { requiresAuth: true, requiresPermission: "editResidents" }
    },{
        path: '/beheer-bewoners-bestanden',
        name: 'beheerBewonersBestanden',
        component: manageResidentsFiles,
        meta: { requiresAuth: true, requiresPermission: "editResidents" }
    }, {
        path: '/bewoners',
        name: 'bewoners',
        component: Residents,
        meta: { requiresAuth: true, requiresPermission: "seeResidents" }
    }, {
        path: '/agenda/:id',
        name: 'agendaId',
        component: Announcements,
        meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
    }, {
        path: '/agenda/nieuw',
        name: 'agendaNieuw',
        component: Announcements,
        meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
    }, {
        path: '/agenda',
        name: 'agenda',
        component: Announcements,
        meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
    }, {
        path: '/updatePlatformMQTTPassword',
        name: 'updatePlatformMQTTPassword',
        component: UpdatePlatformMQTTPassword,
        meta: { requiresAuth: true, requiresPermission: "editPlatform" }
    }, {
        path: '/wachtwoord-vergeten',
        name: 'wachtwoordVergetenVerzoek',
        component: RequestPasswordReset
    }, {
        path: '/wachtwoord-vergeten/:key',
        name: 'wachtwoordVergeten',
        component: ResetPassword
    }, {
        path: '/nieuw-emailadres-valideren/:key',
        name: 'nieuwEmailValideren',
        component: ConfirmNewEmail
    }, {
        path: '/interesses',
        name: 'interesses',
        component: Interests,
        meta: { requiresAuth: true, requiresPermission: "seeTags", requiresSystem: true }
    }, {
        path: '/systemen/:id/bestanden',
        name: 'beheerSysteemBestanden',
        component: manageFacilityFiles,
        meta: { requiresAuth: true, requiresPermission: "editSystems", requiresSystem: true }
    }, {
        path: '/systemen',
        name: 'systemen',
        component: Systems,
        meta: { requiresAuth: true, requiresPermission: "seeSystems" }
    }, {
        path: '/systeem-menu',
        name: 'systeemMenu',
        component: SystemMenu,
        meta: { requiresAuth: true, requiresPermission: "seeSystems" }
    }, {
        path: '/api-sleutel',
        name: 'apiSleutel',
        component: Api_key,
        meta: { requiresAuth: true, requiresPermission: "editSystems" }
    }, {
        path: '/koppel-apparaat',
        name: 'koppelApparaat',
        component: LinkDevice,
        meta: { requiresAuth: true}
    }, {    
        path: '/koppel-apparaat/code/:code',
        name: 'koppelApparaatCode',
        component: LinkDevice,
        meta: { requiresAuth: true}
    }, {      
        path: '/koppel-apparaat/stream/:stream',
        name: 'koppelApparaatStream',
        component: LinkDevice,
        meta: { requiresAuth: true}
    }, {      
        path: '/gekoppelde-apparaten',
        name: 'gekoppeldeApparaten',
        component: LinkedDevices,
        meta: { requiresAuth: true}
    }, {    
        path: '/gekoppelde-apparaten/:id',
        name: 'beheerGekoppeldApparaten',
        component: manageLinkedDevice,
        meta: { requiresAuth: true}
    }, {    
        path: '/privacy-beleid',
        name: 'privacyBeleid',
        component: privacyPolicy,
        meta: { requiresAuth: false}
    }, {    
        path: '/dagprogramma',
        name: 'dayprogram', // TODO
        component: dayprogram,
        meta: { requiresAuth: true, requiresPermission: "editGeneralDayprogram" }
    }, {
        path: '/tag-systeem',
        name: 'tagSysteem',
        component: TagSystem,
        meta: { requiresAuth: true, requiresPermission: "editTags" }
    }, {
        path: '/registreren/:id?',
        name: 'registreren',
        component: Register,
        meta: { requiresAuth: false }
    }, {
        path: '/activeren/:token',
        name: 'activeren',
        component: ConfirmEmail,
        meta: { requiresAuth: false }
    }, {
        path: '/wachtwoord-aanmaken/:token',
        name: 'activerenWachtwoord',
        component: ConfirmEmailAddPassword,
        meta: { requiresAuth: false }
    }, {
        path: '/',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true }
    }
]

export default routes
