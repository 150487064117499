<template>
    <div>
        <div class="header">
            <div id="hamburger" @click="toggleMenu">
                <v-icon>mdi-menu</v-icon>
            </div>
            <span class="website-title">
                mijn Mr illi
            </span>
        </div>
        <v-navigation-drawer v-model="showNavigation" app left dark width="300" class="nav-drawer">
            <div style="display: flex; flex-direction: column; height: 100%;">
            <v-list>
                <v-list-item two-line v-if="authenticated" link>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div style="color: rgba(255,255,255,0.5);">{{ $t('theNavigation.loggedInAs') }}:</div>
                            <div style="font-weight: bold;">{{ user.name }}</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider v-if="authenticated" ></v-divider>
                <v-list-item v-if="authenticated" link :to="{
                    name: 'account'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.account') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item two-line v-if="system" link :to="{
                    name: 'systeemMenu'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <div style="color: rgba(255,255,255,0.5);">{{ $t('theNavigation.configuringSystem') }}:</div>
                            <div style="font-weight: bold;">illi-{{ system.type }}-{{ system.number }}</div>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <v-list-item v-if="authenticated && system" link :to="{
                    name: 'bewoners'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.residents') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="authenticated && system" link :to="{
                    path: `/systems/${system.id}/files`
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-file</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.manageFacilityFiles') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="authenticated && system" link :to="{
                    name: 'interesses'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.interests') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="authenticated && user.permissions.seeSystems" exact link :to="{
                    name: 'systemen'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-raspberry-pi</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.systems') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->
                <v-list-item v-if="authenticated" exact link :to="{
                    name: 'gekoppeldeApparaten'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-television</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.stream') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- TODO -->
                <v-list-item v-if="authenticated && user.permissions.editGeneralDayprogram" exact link :to="{
                    name: 'dayprogram'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-format-columns</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.dayprogram') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="authenticated && user.permissions.editTags" exact link :to="{
                    name: 'tagSysteem'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-tag</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.tagSystem') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item v-if="authenticated && user.permissions.editGeneralFiles" link :to="{
                    name: 'database'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-format-list-checkbox</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.database') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item> -->


                <!-- Not authenticated -->
                <v-list-item v-if="!authenticated" link :to="{
                    name: 'login'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-login-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.signIn') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!authenticated" link :to="{
                    name: 'registreren'
                }">
                    <v-list-item-icon>
                        <v-icon>mdi-account-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t('theNavigation.register') }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            
            <div style="margin-top: auto;">
                    <!-- Sign out -->
                    <v-list-item v-if="authenticated" link @click.prevent="signOut">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t('theNavigation.signOut') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {
        mapGetters,
        mapActions
    } from 'vuex'
    export default Vue.extend({
        data: () => ({
            nav: null,
            showNavigation: false
        }),
        computed: {
            ...mapGetters({
                authenticated: "auth/authenticated",
                user: "auth/user",
                system: "system/system"
            })
        },
        created() {
            this.showNavigation = (window.innerWidth > 1263);
        },
        methods: {
            ...mapActions({
                signOutAction: 'auth/signOut'
            }),
            signOut() {
                this.signOutAction().then(() => {
                    console.log(this.$route.path)
                    if (this.$route.path != '/')
                        this.$router.replace({
                            name: 'home'
                        })
                })
            },
            toggleMenu(){
                this.showNavigation = !this.showNavigation;
            },
            updateVh() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
        },
        mounted() {
            this.updateVh();
            window.addEventListener('resize', this.updateVh);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateVh);
        },
    })
</script>

<style>

    @media screen and (max-width: 1263px) {  
        *{
            scroll-margin-top: 60px;
        } 
        .header{    
            position: fixed;
            z-index: 99;
            background: white;
            width: 100vw;
            height: 60px;
            border-bottom: 2px solid #757575;
            display: flex;
        }
        .header #hamburger{
            display: flex;
            width: 60px;
            height: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .header .website-title{
            line-height: 60px;
            font-weight: 600;
            font-size: 22px;
            color: #4f4f63;
        }
        .v-navigation-drawer{
            top: 60px!important;
        }
        .nav-drawer {
            height: calc(var(--vh, 1vh) * 100 - 60px) !important;
        }
        .v-main{
            margin-top: 60px;
        }
    }

    @media screen and (min-width: 1264px) {  
        .header{
            display: none;
        }
    }
    .nav-drawer {
        background-image: url('../assets/images/background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left;
    }

</style>