import Vue from 'vue';
import VueI18n from 'vue-i18n';
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesNL from 'vee-validate/dist/locale/nl';

Vue.use(VueI18n);

const messages = {
    'en': {
        validations: validationMessagesEN,
        home: {
            home: "Home"
        },
        theNavigation: {
            home: "Home",
            signIn: "Sign in",
            loggedInAs: "Logged in as",
            configuringSystem: "Configuring system",
            residents: "Residents",
            interests: "Interests",
            systems: "Systems",
            stream: "Device management",
            database: "Database",
            account: "My Account",
            dayprogram: "Dayprogram",
            signOut: "Sign Out"
        },
        interests: {
            interests: "Interests",
            edit: "Edit",
            save: "Save",
            close: "Close",
            noInterestsFound: "No interests found",
            noResultsFound: "No tags found",
            search: "Search",
            tagsToChooseFrom: "Tags to choose from",
            interestsFromResidents: "Interests from the residents",
            tagCategory: "Category",
            saved: "Saved",
            notSaved: "Failed to save"
        },
        login: {
            title: "Login",
            subheader: "Please log in.",
            label_email: "Email",
            label_password: "Password",
            label_submit: "Log In",
            login_failed: "Failed to log in!",
            link_forgot_password: "Forgot password"
        },
        account: {
            title: "Account Settings",
        },
        linkDevice: {
            stream: "Mr illi devices",
            search: "search",
            title_link_device: "Link device",
            title_manage_device: "Manage system",
            title_edit_device: "Edit",
            info: "Info",
            customize: "Personalize",
            code: "Code",
            next: "Next",
            connect: "Connect",
            name_device: "Name device",
            city: "City",
            display_devices: "Show devices",
            retry: "Try again",
            link: "Link device",
            linked_devices: "Linked devices",
            unlink: "Unlink",
            unlinkTitle: "Unlink a device",
            unlinkDescription: "Are you sure you want to unlink this device?",
            manage: "Manage",
            submit: "Save",
            tag: {
                label: 'Interests'
            },
            error: "Something went wrong",
            log: {
                title: "Logs",
                tabs: {
                    activity: "Activity",
                    clicks: "Clicks",
                }
            },
            no_devices: "No devices found",
            favorite: "Favorite videos",
            table: {
                name: "device",
                id: "id",
                city: "city",
                tags: "tags",
                user: "user",
                status: {
                    online: "online",
                    offline: "offline",
                    unlinked: "unlinked",
                }
            },
            moment: {
                label: "Food & Drinks",
                table: {
                    name: "Name",
                    from: "From",
                    to: "To",
                    active: "Active",
                },
                clock: {
                    done: "Save",
                    cancel: "Cancel",
                }
            },
            announcementCategories: {
                label: "Special days"
            },
            personalAnnouncements: {
                label: "Calendar",
                overview: "Overview",
                appearance: "Appearance",
                display: "Display",
                addAnnouncement: "Add",
                time: {
                    start: "Start time",
                    end: "End time",
                    info: "Which time is displayed on the announcement"
                },                
                days: "Days",
                addDay: "Add day",
                times: "Times",
                addTime: "Add Time",
                timeInfo: "At which time the announcement gets displayed",
                timeInfo2: "Leave empty to display spread around the day",
                daysBefore: "Days before",
                daysBeforeInfo: "How many days in advance the announcement is visible",
                daysBeforeInfo2: "(0 is just the day itself)",
                repeat: "Repeat",
                repeatOptions: {
                    none: "Never",
                    day: "Daily",
                    week: "Weekly",
                    month: "Monthly",
                    year: "Yearly",
                },
                duration: "Duration",
                durationSuffix: "seconds",
                actions: "Actions",
                delete: "Delete",
                preview: "Preview",
                modal: {
                    delete: {
                        title: "Delete announcement",
                        text: "Are you sure you want to delete the announcement?",
                        cancel: "Cancel",
                        confirm: "Delete",
                    }
                },
                tagOptions: {
                    cities: "Cities",
                    music: "Music",
                    nature: "Nature & animals",
                    countries: "Countries",
                    sport: "Sport",
                    other: "Other",
                }
            },
            cancel: "Back",
            createLocation: {
                button: "Add location",
                title: "Add location",
                location: "location",
                user: "User",
                add: "Add location",
            },
            delete: {
                title: "Delete location",
                description: "Are you sure you want to delete this device?",
                button: "Delete"
            },
            dropdown: {
                edit: "Edit",
                logs: "Logs",
                unlink: "Unlink",
                link: "Link",
                delete: "Delete",
            },
            requestTagConfirmation: "Request successful",
            requestTagError: "Something went wrong",
            editAll: "Edit",
        },
        systems: {
            title: "Systems",
            search: "Search",
            online: "Online",
            offline: "Offline",
            manage: "Manage",
            name: "Name",
            location: "Location",
            organisation: "Organisation",
            department: "Department",
        },
        systemMenu: {
            online: {
                isOnline: "Online",
                isOffline: "Offline",
                lastSeen: "Last seen at {date}"
            },
            changeInterests: {
                title: "Change Interests",
                description: "Change the interests of the residents."
            },
            syncGeneral: {
                title: "Synchronize general media",
                description: "Get the latest general media files from the illi server."
            },
            syncPrivate: {
                title: "Synchronize personal media",
                description: "Get the latest personal media files from the illi server."
            },
            syncStatesTexts: {
                requesting: "Started request",
                failed: "Failed",
                starting: "Starting",
                syncing: "Syncing",
                done: "Finished"
            },
            api_key: {
                title: "API Key",
                description: "Get a new API Key for this system."
            },
            reboot: {
                title: "Reboot device",
                description: "Reboot this system.",
                confirmReboot: {
                    title: "Are you sure you want to reboot?",
                    description: "The device will temporary lose the connection to the internet during the rebooting process.",
                    reboot: "Reboot",
                    cancel: "cancel"
                },
                stateTextReboot: {
                    rebooting: "Rebooting..."
                }
            },
            manage_residents:{
                title: "Manage the residents",
                description: "Manage the residents on this system",
                add_resident: "Add resident",
                search_resident: "Search for resident",
                loading_text: "Loading...",
                manage_resident: "Manage resident",
                resident_succesfully_added_title: "Resident is added!", 
                resident_succesfully_added_description: "You can see the new resident in the list of residents",
                new_resident_name: "Enter resident's name",
                resident_name: "Resident's name",
                add: "Add",
                cancel: "Cancel",
                server_error: "Server Error, sorry for the inconvenience",
                insert_name: "Please type a name",
                manage_info:"Manage personal info",
                manage_info_description: "Edit resident's name",
                manage_files: "Manage files",
                manage_files_description: "Manage this resident's files",
                delete_resident: "Delete resident",
                delete_resident_description: "Remove the resident from all illi-servers and illi-systems",
                delete_resident_confirm: "Are you sure you want to delete this profile?",
                delete_resident_description_dialog: "If you delete this account, it will be deleted from all illi-tv-servers and systems. You won't be able to restore it anymore.",
                save: "Save",
                manage_files_for: "Files for ",
                search_files:"File(s) search",
                previous_file: "Previous file",
                upload_files: "Files upload",
                click_here_to_upload: "Click here to upload files",
                files_successfully_uploaded: "Files are successfully uploaded!",
                file_is_deleted: "Files have been deleted successfully!",
                edit_file: "Edit the file",
                file_new_name: "File new name",
            }
        },
        requestPasswordReset: {
            title: "Reset your password",
            subheader: "Forgot your password? That's okay. It happens to the best of us. Enter your email address here and we will send you a email with a link to where you can change your password.",
            label_email: "Email",
            label_submit: "Send Email",
            sent: "A email has been send, if you are registered",
            no_email: "Did you enter a valid email address?",
            error: "Something went wrong! We are currently hard working to fix the problem.",
            close: "Close"
        },
        resetPassword: {
            title: "Change your password",
            subheader: "Here you can change your password. The new password should be at least 8 characters, but more is better.",
            label_email: "Email",
            label_password: "New password",
            label_submit: "Save changed password",
            saved: "Changed password saved!",
            success: "You will be redirected to the login page within the next 5 seconds..."
        },
        api_key: {
            api_key_for: "Change API-Key for {system}",
            explanation: "The system uses an API-Key to identify itself to the API. This key is hashed before it is saved. This makes it impossible to show the saved API-Key. Here you can generate a new key to be used by the system.",
            warning: "Caution! This system already has an API-Key registered. If you generate a new key, it will invalidate the old one.",
            create_new_key: "Create new APOI-Key",
            api_key_being_generated: "A new key is being generated...",
            title_new_settings: "New API-Key",
            your_new_key: "Your new key:",
            steps: {
                title: "Follow these steps to save the new key on your system:",
                a: "Access your system via SSH.",
                b: "Execute the following command.",
                c: "{id} {system}"
            }
        },
        database: {
            title: "Database",
            loading: "Loading...",
            previous_file: "Previous directory",
            add: "Add",
            your_selection: "Your selection",
            continue: "Continue",
            select_files: "Selected files will show up here",
            delete: "Are you sure you want to remove this item from the list?",
            delete_all_button: "Remove All",
            delete_all: "Remove all selected files?",
            delete_all_explanation: "Are you sure you want to remove the whole list with selected items?",
            cancel: "Cancel",
            ok: "Ok",
            upload_files: "Files upload",
            click_here_to_upload: "Click here to upload files",
            files_successfully_uploaded: "Files are successfully uploaded!",
            server_error: "Server Error, sorry for the inconvenience",
            submit: "Upload",
            make_directory: "Make directory",
            add_directory: "Make directory",
            dir_name: "Directory name",
            save: "Save",
            selected: {
                title: "Your selection",
                add_locations: "Add locations",
                previous_page: "Previous page",
                finish: "Finish",
                care_locations_list: "List of Care facilities",
                find_location: "Find a Care facility",
                no_location: {
                    title: "You haven't selected a care facility yet!",
                    text: "Select one or multiple care facilities to finish."
                },
                no_files: {
                    title: "You haven't selected a file yet!",
                    text: "Select one or multiple files to finish."
                },
                confirm_delete: "Are you sure you want to remove this item from the list?",
                files_uploaded: {
                    title: "Success!",
                    text: "The files have been successfully copied the the specified location."
                },
                table_headers: {
                    file_name: "File name",
                    locations: "Care facility",
                    remove: "Remove",
                    organisations: "Organisations",
                    private_directory: "Private directory"
                },
                return: {
                    title: "Are you sure you want to go back?",
                    text: "You will lose your progress when returning to the database page.",
                    affirm: "Go back",
                    cancel: "Stay here"
                },
                stepper: {
                    organisation: {
                        search: "Search..."
                    }
                }
            }
        },
        months: {
            january: "january",
            february: "february",
            march: "march",
            april: "april",
            may: "may",
            june: "june",
            july: "july",
            august: "august",
            september: "september",
            october: "october",
            november: "november",
            december: "december",
        },
        dayprogram: {
            title: 'dayprogram'
        }
    },
    'nl': {
        validations: validationMessagesNL,
        home: {
            home: "Home"
        },
        theNavigation: {
            home: "Home",
            signIn: "Inloggen",
            register: "Registreren",
            loggedInAs: "Ingelogd als",
            configuringSystem: "Configureert systeem",
            residents: "Bewoners",
            manageFacilityFiles: "Zorghuis",
            interests: "Interesses",
            systems: "Systeemoverzicht",
            stream: "Apparaatbeheer",
            database: "Database",
            account: "Mijn Account",
            dayprogram: "Dagprogramma",
            tagSystem: "Tagsysteem",
            signOut: "Uitloggen"
        },
        interests: {
            interests: "Interesses",
            edit: "Bewerken",
            save: "Opslaan",
            close: "Sluiten",
            noResultsFound: "Geen tags gevonden",
            noInterestsFound: "Geen interesses gevonden",
            search: "Zoeken",
            tagsToChooseFrom: "Tags om uit te kiezen",
            interestsFromResidents: "Interesses van de bewoners",
            tagCategory: "Categorie",
            saved: "Opgeslagen",
            notSaved: "Opslaan mislukt"
        },
        login: {
            title: "Inloggen",
            subheader: "Log alstublieft eerst in.",
            label_email: "Mailadres",
            label_password: "Wachtwoord",
            label_submit: "Inloggen",
            login_failed: "Inloggen mislukt!",
            link_forgot_password: "Wachtwoord vergeten"
        },
        register: {
            title: "Registreren",
            description: "Maak een account aan om gebruik te maken van Mr illi.",
            email: "Mailadres",
            password: "Wachtwoord",
            passwordConfirm: "Wachtwoord bevestigen",
            firstName: "Voornaam",
            lastName: "Achternaam",
            type: "Ik maak dit account aan voor:",
            organisation: "Organisatie",
            confirm: {
                label: "Ik ga akkoord met de",
                terms: "Algemene voorwaarden",
                and: "en",
                privacy: "Privacyverklaring",
                newTab: "Opent in een nieuw tabblad"
            },
            submit: "Registreren",
            next: "Volgende",
            previous: "Vorige",
        },
        account: {
            title: "Account Instellingen",
            description: "Hier kunt u uw account instellingen aanpassen. Gebruik de tabs hieronder om uw persoonlijke gegevens en wachtwoord te wijzigen.",
            descriptionPersonal: "Hier kunt u uw persoonlijke gegevens aanpassen",
            descriptionPassword: "Hier kunt u uw wachtwoord wijzigen",
            changePersonalInfo: "Persoonlijke gegevens",
            changePassword: "Wachtwoord",
            label_currentEmail: "Huidig mailadres",
            label_name: "Naam",
            label_email: "Mailadres",
            label_currentPassword: "Huidig wachtwoord",
            label_newPassword: "Nieuw wachtwoord",
            label_newPasswordConfirm: "Nieuw wachtwoord bevestigen",
            label_submit: "Opslaan"
        },
        confirmEmail: {
            success: {
                title: "Uw account is geactiveerd!",
                text: "U kunt nu inloggen met uw account."
            },
            fail: {
                title: "Er ging iets fout!",
                text: "Probeer het nog eens of neem contact op via info@illi-tv.nl."
            }
        },
        confirmNewEmail: {
            title: "Nieuw mailadres bevestigen",
            subheader: "Bezig met het bevestigen van uw nieuwe mailadres, een moment geduld aub.",
            success: "Het updaten van het mailadres is gelukt. U wordt nu doorgeleidt naar het login pagina."
        },
        confirmEmailAddPassword: {
            title: "Wachtwoord instellen",
            password: "Wachtwoord",
            passwordConfirm: "Wachtwoord bevestigen",
            confirm: {
                label: "Ik ga akkoord met de",
                terms: "Algemene voorwaarden",
                and: "en",
                privacy: "Privacyverklaring",
                newTab: "Opent in een nieuw tabblad"
            },
            submit: "Account aanmaken",
        },
        linkDevice: {
            stream: "Apparaten Mr illi",
            search: "Zoeken",
            title_link_device: "Apparaat linken",
            title_manage_device: "Apparaat beheren",
            title_edit_device: "Aanpassen",
            info: "Gegevens",
            customize: "Personaliseren",
            code: "Code",
            next: "Volgende",
            connect: "Verbinden",
            name_device: "Naam apparaat",
            city: "Plaats apparaat",
            display_devices: "Bekijk apparaten",
            retry: "Opnieuw proberen",
            link: "Apparaat koppelen",
            linked_devices: "Gekoppelde apparaten",
            unlink: "Ontkoppelen",
            unlinkTitle: "Een apparaat ontkoppelen",
            unlinkDescription: "Weet u zeker dat u dit apparaat wilt ontkoppelen?",
            manage: "Beheren",
            submit: "Opslaan",
            tag: {
                label: 'Interesses',
            },
            error: "Er ging iets fout",
            log: {
                title: "Logboek",
                tabs: {
                    activity: "Activiteit",
                    clicks: "Kliks",
                }
            },
            no_devices: "Geen apparaten gevonden",
            favorite: "Favoriete video's",
            table: {
                name: "apparaat",
                id: "id",
                city: "plaats",
                tags: "tags",
                user: "gebruiker",
                status: {
                    online: "online",
                    offline: "offline",
                    unlinked: "niet gekoppeld",
                }
            },
            moment: {
                label: "Eten & Drinken",
                table: {
                    name: "Naam",
                    from: "Van",
                    to: "Tot",
                    active: "Actief",
                },
                clock: {
                    done: "Opslaan",
                    cancel: "Annuleren",
                }
            },
            announcementCategories: {
                label: "Speciale dagen"
            },
            announcements: {
                label: "Agenda",
                overview: "Overzicht",
                overviewToggle: {
                    show: 'weergeven',
                    hide: 'verbergen',
                },
                add: "Nieuw agendapunt",
                noUpcomingDays: "Geen opkomende agendapunten",
                date: "Datum",
                title: "Titel",
                time: {
                    start: "Begintijd",
                    until: "tot",
                    end: "Eindtijd",
                },
                repeat: "Frequentie",
                location: "Locatie (optioneel)",
                description: "Beschrijving (optioneel)",
                icon: "Icoon (optioneel)",
                preview: "Toon voorbeeld",
                delete: "Verwijderen",
                save: {
                    add: "Toevoegen",
                    edit: "Aanpassen"
                },
                validation: {
                    rule: {
                        max: 'Max.',
                        chars: 'karakters'
                    },
                    success: "Succesvol",
                    error: {
                        charLimit: "Karakterlimiet overschreden",
                        time: "Starttijd moet eerder zijn dan eindtijd",
                        required: "Vul alle velden in",
                        timeConflict: "Agendapunt mag niet op dezelfde tijd beginnen als andere agendapunten",
                    }
                }, 
                repeatOptions: {
                    once: 'Eenmalig',
                    daily: 'Dagelijks',
                    weekly: 'Wekelijks op',
                    monthly: 'Maandelijks op de',
                    monthlyWeek: 'Maandelijks op de',
                    yearly: 'Jaarlijks op',
                },
                modal: {
                    preview: {
                        title: 'Voorbeeld',
                        close: 'Sluiten'
                    }
                }
            },
            personalAnnouncements: {
                label: "Agenda",
                overview: "Overzicht",
                appearance: "Uiterlijk",
                display: "Weergave",
                addAnnouncement: "Toevoegen",
                time: {
                    start: "Begintijd",
                    end: "Eindtijd",
                    info: "Welke tijd wordt weergegeven op de aankondiging"
                },
                days: "Dagen",
                addDay: "Dag toevoegen",
                times: "Tijden",
                addTime: "Tijd toevoegen",
                timeInfo: "Op welke tijd(en) de aankondiging wordt weergegeven",
                timeInfo2: "Laat leeg om verspreid over de hele dag weer te geven",
                daysBefore: "Dagen van te voren",
                daysBeforeInfo: "Hoeveel dagen van te voren de aankondinging weergegeven wordt",
                daysBeforeInfo2: "(0 is enkel op de dag zelf)",
                repeat: "Herhaling",
                repeatOptions: {
                    none: "Niet",
                    day: "Dagelijks",
                    week: "Wekelijks",
                    month: "Maandelijks",
                    year: "Jaarlijks",
                },
                duration: "Duur",
                durationSuffix: "seconden",
                actions: "Acties",
                delete: "Verwijderen",
                preview: "Voorbeeld",
                modal: {
                    delete: {
                        title: "Aankonding verwijderen",
                        text: "Weet u zeker dat u de aankondiging wilt verwijderen?",
                        cancel: "Annuleren",
                        confirm: "Verwijderen",
                    }
                },
                tagOptions: {
                    cities: "Steden & plaatsen",
                    music: "Muziek",
                    nature: "Natuur & dieren",
                    countries: "Landen",
                    sport: "Sport",
                    other: "Overig",
                }
            },
            dayprogram: {
                label: "Dagprogramma"
            },
            cancel: "Terug",
            createLocation: {
                button: "Locatie toevoegen",
                title: "Locatie toevoegen",
                location: "Locatie",
                user: "Gebruiker",
                add: "Locatie toevoegen",
            },
            delete: {
                title: "Een locatie verwijderen",
                description: "Weet u zeker dat u deze locatie wilt verwijderen?",
                button: "Verwijderen"
            },
            dropdown: {
                edit: "Aanpassen",
                logs: "Logboek",
                unlink: "Ontkoppelen",
                link: "Koppelen",
                delete: "Verwijderen",
            },
            requestTag: {
                response: {
                    success: "Verzoek ingediend",
                    error: "Er ging iets fout"
                },
                modal: {
                    title: "Interesse aanvragen",
                    fields: {
                        tag: "Interesse",
                        name: "Naam",
                        email: "E-mailadres",
                        toggleAccounts: "Aan alle apparaten toevoegen",
                        accounts: "Toevoegen aan apparaten",
                        remark: "Opmerking",
                    },
                    buttons: {
                        submit: "Interesse aanvragen",
                        cancel: "Annuleren"
                    }
                }
            },
            editAll: "Aanpassen",
            changes: {
                tags: "Aanpassingen worden toegevoegd aan de huidige interesses.",
                favorites: "Aanpassingen worden toegevoegd aan de huidige favorieten.",
                moments: "Aanpassingen vervangen de huidige eet & drink momenten.",
                specialDays: "Aanpassingen vervangen de huidige speciale dagen.",
                announcements: "Aanpassingen worden toegevoegd aan de huidige agenda.",
                dayprogram: "Aanpassingen vervangen het huidige dagprogramma.",
                photoAlbum: "",
            },
            photoAlbum: "Fotoalbums"
        },
        systems: {
            title: "Systeemoverzicht",
            search: "Zoeken",
            online: "Online",
            offline: "Offline",
            manage: "Beheer",
            name: "Naam",
            location: "Locatie",
            organisation: "Organisatie",
            department: "Afdeling",
        },
        manageFacilityFiles: {
            cancel: "Annuleren",
            server_error: "Server Error, excuus voor het ongemak",
            save: "Opslaan",
            manage_files_for: "Bestanden voor ",
            search_files:"Bestand(en) zoeken",
            previous_file: "Vorige bestand",
            upload_files: "Bestanden uploaden",
            click_here_to_upload: "Klik hier om bestanden te uploaden",
            files_successfully_uploaded: "Bestanden zijn succesvol geüpload!",
            file_is_deleted: "Bestand(en) zijn succesvol verwijderd!",
            edit_file: "Bestand bewerken",
            file_new_name: "Nieuwe naam van het bestand",
            insert_name: "Geef een bestandsnaam op.",
            submit: "Toevoegen",
        },
        systemMenu: {
            online: {
                isOnline: "Online",
                isOffline: "Offline",
                lastSeen: "Laatst gezien: {date}"
            },
            changeInterests: {
                title: "Interesses aanpassen",
                description: "Pas de interesses van de bewoners aan."
            },
            syncGeneral: {
                title: "Synchroniseer algemene media",
                description: "Haal de laatste algemene mediabestanden op van de illi-server."
            },
            syncPrivate: {
                title: "Synchroniseer persoonlijke media",
                description: "Haal de laatste persoonlijke mediabestanden op van de illi-server"
            },
            syncStatesTexts: {
                requesting: "Aanvraag gestart",
                failed: "Mislukt",
                starting: "Aan het starten",
                syncing: "Aan het inladen",
                done: "Klaar"
            },
            manageFacilityFiles: {
                title: "Zorghuis beheren",
                description: "Beheer de mediabestanden op dit systeem."
            },
            api_key: {
                title: "API-Sleutel",
                description: "Maak een nieuwe API-sleutel voor dit systeem."
            },
            manage_residents:{
                title: "Bewoners beheren",
                description: "Beheer de bewoners van dit systeem",
                add_resident: "Bewoner toevoegen",
                search_resident: "Zoek naar een bewoner",
                loading_text: "Aan het laden...",
                manage_resident: "Bewoner beheren",
                resident_succesfully_added_title: "Bewoner is toegevoegd !", 
                resident_succesfully_added_description: "U kunt de nieuwe bewoner in de lijst van de bewoners zien",
                new_resident_name: "Voer de naam van de bewoner in",
                resident_name: "Bewoner naam",
                add: "Toevoegen",
                cancel: "Annuleren",
                server_error: "Server Error, excuus voor het ongemak",
                insert_name: "Typ een naam a.u.b.",
                manage_info:"Persoonsgegevens aanpassen",
                manage_info_description: "Pas de naam van de bewoner aan",
                manage_files: "Bestanden beheren",
                manage_files_description: "Beheer de bestanden van deze bewoner",
                delete_resident: "Bewoner verwijderen",
                delete_resident_description: "Verwijder de bewoner van alle illi-servers en illi-systemen",
                delete_resident_confirm: "Weet u zeker dat u dit profiel wilt verwijderen?",
                delete_resident_description_dialog: "Als u dit account verwijdert, wordt het van alle illi-tv-servers en -systemen verwijderd. U kunt het niet meer herstellen.",
                save: "Opslaan",
                manage_files_for: "Bestanden voor ",
                search_files:"Bestand(en) zoeken",
                previous_file: "Vorige bestand",
                upload_files: "Bestanden uploaden",
                click_here_to_upload: "Klik hier om bestanden te uploaden",
                submit: "Toevoegen",
                files_successfully_uploaded: "Bestanden zijn succesvol geüpload!",
                file_is_deleted: "Bestand(en) zijn succesvol verwijderd!",
                edit_file: "Bestand bewerken",
                file_new_name: "Nieuwe naam van het bestand",
            },
            reboot: {
                title: "Apparaat herstarten",
                description: "Dit apparaat herstarten",
                confirmReboot: {
                    title: "Weet u zeker dat u dit apparaat wil herstarten?",
                    description: "Door het systeem te herstarten, wordt de verbinding tijdelijk verbroken.",
                    reboot: "Herstarten",
                    cancel: "Annuleren"
                },
                stateTextReboot: {
                    rebooting: "Aan het herstarten..."
                }
            }
        },
        requestPasswordReset: {
            title: "Wijzig uw wachtwoord",
            subheader: "Wachtwoord vergeten? Vul hier uw mailadres in en we zullen u een mail sturen met een link waar u uw wachtwoord kan veranderen.",
            label_email: "Mailadres",
            label_submit: "Mail versturen",
            sent: "Als u geregistreerd staat, is er nu een mail verzonden.",
            error: "Er is iets mis gegaan. We zijn hard bezig om deze fout te verhelpen.",
            no_email: "Heeft u wel een geldig mailadres ingevuld?",
            close: "Sluiten"
        },
        resetPassword: {
            title: "Verander uw wachtwoord",
            subheader: "Hier kunt u uw wachtwoord veranderen. Het nieuwe wachtwoord moet minstens 8 karakters lang zijn.",
            label_email: "Mail",
            label_password: "Nieuw wachtwoord",
            label_submit: "Nieuw wachtwoord opslaan",
            saved: "Nieuw wachtwoord opgeslagen!",
            success: "U wordt binnen 5 seconden doorgestuurd naar de inlogpagina..."
        },
        api_key: {
            api_key_for: "API-Sleutel aanpassen voor {system}",
            explanation: "Het systeem gebruikt een API-sleutel om zich te identificeren bij de API. Deze sleutel wordt gehasht opgeslagen. Hierdoor is het niet meer mogelijk om de API-sleutel weer te geven. U kunt hier een nieuwe sleutel genereren die uw systeem kan gebruiken.",
            warning: "Pas op! Dit systeem heeft al een API-Sleutel geregistreerd staan. Als u een nieuwe genereerd, is de oude niet meer geldig.",
            create_new_key: "Nieuwe API-Sleutel maken",
            api_key_being_generated: "Een nieuwe sleutel wordt gegenereerd...",
            title_new_settings: "Nieuwe API-Sleutel",
            your_new_key: "Uw nieuwe sleutel:",
            steps: {
                title: "Volg deze stappen om de nieuwe API-sleutel op uw systeem op te slaan:",
                a: "Log in op het systeem via SSH.",
                b: "Voer onderstaande opdracht uit.",
                c: "{id} {system}"
            }
        },
        database: {
            title: "Database",
            loading: "Aan het laden...",
            previous_file: "Vorige bestand",
            add: "Toevoegen",
            your_selection: "Uw selectie",
            continue: "Doorgaan",
            select_files: "Geselecteerde bestanden worden hier weergegeven",
            delete: "Weet u zeker dat u dit wil verwijderen?",
            delete_all: "Alle geselecteerde bestanden verwijderen?",
            delete_all_button: "Alles verwijderen",
            delete_all_explanation: "Weet je zeker dat je de hele lijst met geselecteerde bestanden wil verwijderen?",
            cancel: "Annuleren",
            ok: "Oke",
            upload_files: "Bestanden uploaden",
            click_here_to_upload: "Klik hier om bestanden te uploaden",
            files_successfully_uploaded: "Bestanden zijn succesvol geüpload!",
            server_error: "Server Error, excuses voor het ongemak.",
            submit: "Uploaden",
            make_directory: "Folder maken",
            add_directory: "Folder aanmaken",
            dir_name: "Naam van folder",
            save: "Opslaan",
            selected: {
                title: "Uw selectie",
                add_locations: "Zorglocatie(s) toevoegen",
                previous_page: "Vorige pagina",
                finish: "Afronden",
                care_locations_list: "Lijst van alle zorglocaties",
                find_location: "Zoek naar een zorglocatie",
                no_location: {
                    title: "U heeft nog geen zorglocatie geselecteerd!",
                    text: "Selecteer een zorglocatie voordat u gaat afronden!"
                },
                no_files: {
                    title: "U heeft nog geen bestanden geselecteerd!",
                    text: "Selecteer eerst bestand(en) om een zorglocatie toe te voegen!"
                },
                confirm_delete: "Weet je zeker dat je dit wilt verwijderen?",
                files_uploaded: {
                    title: "Gelukt!",
                    text: "De bestanden zijn succesvol geupload!"
                },
                table_headers: {
                    file_name: "Bestandsnaam",
                    locations: "Zorglocaties",
                    remove: "Verwijder",
                    organisations: "Organisaties",
                    private_directory: "Private map"
                },
                return: {
                    title: "Weet je zeker dat je terug wil gaan?",
                    text: "Je zal je voortgang verliezen wanneer je terug gaat naar de databasepagina.",
                    affirm: "Ga terug",
                    cancel: "Blijf hier"
                },
                stepper: {
                    organisation: {
                        search: "Zoeken..."
                    }
                }
            }
        },
        months: {
            january: "januari",
            february: "februari",
            march: "maart",
            april: "april",
            may: "mei",
            june: "juni",
            july: "juli",
            august: "augustus",
            september: "september",
            october: "oktober",
            november: "november",
            december: "december",
        },
        days: {
            1: "maandag",
            2: "dinsdag",
            3: "woensdag",
            4: "donderdag",
            5: "vrijdag",
            6: "zaterdag",
            7: "zondag",
        },
        dayprogram: {
            title: 'Standaard dagprogramma aanpassen',
            buttons: {
                add: "Toevoegen",
                addTooltip: "Voeg een dagprogrammablok toe",
                today: 'Vandaag',
                prevWeek: 'Vorige week',
                nextWeek: 'Volgende week',
                defaultProgram: 'Standaard dagprogramma inladen',
                undo: 'Ongedaan maken',
                equalize: 'Dagindeling kopiëren',
                save: 'Opslaan',
            },
            modal: {
                addOrEdit: {
                    add: {
                        title: 'Blok toevoegen', // ?
                        save: 'Toevoegen',                        
                    },
                    edit: {
                        title: 'Blok aanpassen', // ?
                        save: 'Aanpassen',
                    },
                    type: {
                        video: 'Video',
                        image: 'Afbeeldingen',
                        album: 'Fotoalbum',
                    },
                    content: 'Onderwerp',
                    noContent: 'Geen resultaten gevonden.',
                    noContentCheckFilter: 'Geen resultaten gevonden. Pas uw filters aan.',
                    date: 'Datum',
                    time: {
                        from: 'begin',
                        to: 'eind',
                    },
                    repeat: "Frequentie",
                    repeatOptions: {
                        once: 'Eenmalig',
                        daily: 'Dagelijks',
                        weekly: 'Wekelijks op',
                        monthly: 'Maandelijks op de',
                        monthlyWeek: 'Maandelijks op de',
                        yearly: 'Jaarlijks op',
                    },
                    cancel: 'Annuleren',
                },
                equalize: {
                    title: 'Dagindeling kopiëren',
                    description: 'Kopieer de indeling van een brondag naar bestemmingsdagen',
                    sourceDay: 'Brondag',
                    targetDays: 'Bestemmingsdagen',
                    cancel: 'Annuleren',
                    save: 'Kopiëren',
                },
                saveProgram: { 
                    title: 'Opslaan mislukt',
                    emptyDays: 'De volgende dagen bevatten lege blokken',
                    addContent: 'Wijs content toe aan de blokken om het dagprogramma op te slaan.',
                    cancel: 'Sluiten',
                },
                defaultProgram: {
                    title: 'Standaard dagprogramma inladen',
                    description: 'Weet u zeker dat u het standaard dagprogramma wilt inladen?',
                    cancel: 'Annuleren',
                    load: 'Inladen',
                }
            }
        },
        photoalbum: {
            title: "Albums",
            buttons: {
                add: "Fotoalbum toevoegen",
                back: "Terug",
                delete: "Fotoalbum verwijderen",
            },
            icon: "icoon",
            photos: "foto's",
            modal: {
                add: {
                    title: "Fotoalbum toevoegen",
                    name: "Naam",
                    cancel: "Annuleren",
                    submit: "Toevoegen"
                },
                delete: {
                    title: "Fotoalbum verwijderen",
                    message: {
                        start: "Weet u zeker dat u fotoalbum",
                        end: "wilt verwijderen?",
                    },
                    cancel: "Annuleren",
                    submit: "Verwijderen"
                }
            },
            error: {
                photoAlbumName: {
                    required: "Albumnaam verplicht.",
                    toLong: "Albumnaam te lang.",
                    alreadyExists: "Albumnaam komt al voor.",
                },
                photoName: {
                    required: "Fotonaam verplicht.",
                    toLong: "Fotonaam te lang.",
                },
                noAlbumsFound: "Geen albums gevonden.",
                spaceLimit: "Opslaglimiet overschreden (maximaal _max_allowed_ toegestaan per systeem)",
                photoAlbumEmpty: "Fotoalbums mogen niet leeg zijn.",
            }
        },
        tagSystem: {
            search: "Zoeken",
            filter: {
                button: "Filter",
                all: "Alle",
                empty: "Leeg",
            },
            button: {
                tags: {
                    add: "Tags toevoegen",
                    delete: "Tags verwijderen",
                    auto: "Automatisch tags toevoegen",
                },
                tag: {
                    edit: "Aanpassen",
                    delete: "Verwijderen",
                },
                delete: {
                    image: "Afbeelding verwijderen",
                    song: "Liedje verwijderen",
                    video: "Video verwijderen",
                },
                add: {
                    image: "Afbeelding toevoegen",
                    song: "Liedje toevoegen",
                    video: "Video toevoegen",
                    tag: "Tag toevoegen",
                },
            },
            modal: {
                selected: "geselecteerd",
                selectAll: "Alle selecteren",
                tags: {
                    add: {
                        title: "Tags toevoegen",
                        tags: "Tags",
                        save: "Tags toevoegen",
                        cancel: "Annuleren",
                    },
                    delete: {
                        title: "Tags verwijderen",
                        save: "Tags verwijderen",
                        cancel: "Annuleren",
                    },
                    auto: {
                        title: "Automatisch tags toevoegen",
                        description: "Weet u zeker dat u automatisch tags wilt toevoegen?",
                        noTags: "Geen automatische tags gevonden",
                        save: "Automatisch tags toevoegen",
                        cancel: "Annuleren",
                    }
                },
                items: {
                    add: {
                        title: {
                            image: "Afbeeldingen toevoegen",
                            video: "Video's toevoegen",
                            song: "Liedjes toevoegen",
                        },
                        table: {
                            image: "Afbeelding",
                            video: "Video",
                            location: "Locatie",
                            useCustomLocation: "Nieuwe map toevoegen",
                            name: "Naam",
                        },
                        cancel: {
                            image: "Annuleren",
                            video: "Annuleren",
                            song: "Annuleren",
                        },
                        save: {
                            image: "Afbeeldingen toevoegen",
                            video: "Video's toevoegen",
                            song: "Liedjes toevoegen",
                        },
                        editAll: "Locatie voor alle aanpassen",
                    },
                    delete: {
                        title: {
                            image: "Afbeeldingen verwijderen",
                            video: "Video's verwijderen",
                            song: "Liedjes verwijderen",
                            tag: "Tags verwijderen"
                        },
                        cancel: {
                            image: "Annuleren",
                            video: "Annuleren",
                            song: "Annuleren",
                            tag: "Annuleren"
                        },
                        save: {
                            image: "Afbeeldingen verwijderen",
                            video: "Video's verwijderen",
                            song: "Liedjes verwijderen",
                            tag: "Tags verwijderen"
                        }
                    }
                },
                tag: {
                    add: {
                        title: "Tag toevoegen",
                        form: {
                            name: "Tag",
                            category: "Categorie",
                        },
                        cancel: "Annuleren",
                        save: "Toevoegen",
                    },
                    edit: {
                        title: "Tag aanpassen",
                        form: {
                            name: "Tag",
                            category: "Categorie",
                        },
                        cancel: "Annuleren",
                        save: "Aanpassen",
                    },
                    delete: {
                        title: "Tag verwijderen",
                        form: {
                            name: "Tag"
                        },
                        cancel: "Annuleren",
                        save: "Verwijderen",
                    }
                }
            },
            tab: {
                video: {
                    item: 'video\'s',
                    title: 'Video\'s',
                    dropdown: {
                        manage: 'Beheer',
                        edit: 'Aanpassen',
                        delete: 'Verwijderen',
                    },
                    modal: {
                        add: {
                            title: 'Video toevoegen',
                            cancel: 'Annuleren',
                            submit: 'Toevoegen',
                        },
                        edit: {
                            title: 'Video aanpassen',
                            header: {
                                thumbnail: 'Thumbnail',
                                tags: 'Tags',
                            },
                            form: {
                                delete: 'Verwijderen',
                                upload: 'Upload',
                                thumbnail: 'Titel',
                                video: 'Video',
                            },
                            cancel: 'Annuleren',
                            submit: 'Aanpassen',
                        },
                        delete: {
                            title: 'Video verwijderen',
                            description: 'Weet u zeker dat u deze video wilt verwijderen?',
                            cancel: 'Annuleren',
                            submit: 'Verwijderen',
                        }
                    }
                },
                image: {
                    item: 'afbeeldingen',
                    title: 'Afbeeldingen',
                    dropdown: {
                        manage: 'Beheer',
                        edit: 'Aanpassen',
                        delete: 'Verwijderen',
                    },
                    modal: {
                        add: {
                            title: 'Afbeelding toevoegen',
                            cancel: 'Annuleren',
                            submit: 'Toevoegen',
                        },
                        edit: {
                            title: 'Afbeelding aanpassen',
                            header: {
                                image: 'Afbeelding',
                                tags: 'Tags',
                            },
                            cancel: 'Annuleren',
                            submit: 'Aanpassen',
                        },
                        delete: {
                            title: 'Afbeelding verwijderen',
                            description: 'Weet u zeker dat u deze afbeelding wilt verwijderen?',
                            cancel: 'Annuleren',
                            submit: 'Verwijderen',
                        }
                    }
                },
                song: {
                    item: 'liedjes',
                    title: 'Liedjes',
                    dropdown: {
                        manage: 'Beheer',
                        edit: 'Aanpassen',
                        delete: 'Verwijderen',
                    },
                    modal: {
                        add: {
                            title: 'Liedje toevoegen',
                            cancel: 'Annuleren',
                            submit: 'Toevoegen',
                        },
                        edit: {
                            title: 'Liedje aanpassen',
                            header: {
                                song: 'Liedje',
                                tags: 'Tags',
                            },
                            cancel: 'Annuleren',
                            submit: 'Aanpassen',
                        },
                        delete: {
                            title: 'Liedje verwijderen',
                            description: 'Weet u zeker dat u dit liedje wilt verwijderen?',
                            cancel: 'Annuleren',
                            submit: 'Verwijderen',
                        }
                    }
                },
                tag: {
                    title: 'Tags',
                    dropdown: {
                        manage: 'Beheer',
                        edit: 'Aanpassen',
                        delete: 'Verwijderen',
                    },
                    modal: {
                        add: {
                            title: 'Tag toevoegen',
                            cancel: 'Annuleren',
                            submit: 'Toevoegen',
                        },
                        edit: {
                            title: 'Tag aanpassen',
                            cancel: 'Annuleren',
                            submit: 'Aanpassen',
                        },
                        delete: {
                            title: 'Tag verwijderen',
                            description: 'Weet u zeker dat u deze tag wilt verwijderen?',
                            cancel: 'Annuleren',
                            submit: 'Verwijderen',
                        }
                    }
                },
                folder: {
                    title: 'Mappen',
                    editButton: 'Aanpassen',
                    modal: {
                        edit: {
                            title: 'Map aanpassen',
                            header: {
                                thumbnail: 'Thumbnail',
                            },
                            form: {
                                delete: 'Verwijderen',
                                upload: 'Upload',
                            },
                            cancel: 'Annuleren',
                            submit: 'Aanpassen',
                        }
                    }
                },
            }
        }
    }
};

export default new VueI18n({
    locale: 'nl', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});