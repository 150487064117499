import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import nl from 'vuetify/lib/locale/nl'

Vue.use(Vuetify)

const opts = {
    lang: {
        locales: { nl },
        current: 'nl',
    },
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#953B3A', // primary color
                secondary: '#3B3D3C', // secondary color
                textPrimary: '#3B3D3C', // primary text color
                textSecondary: '#3B3D3C', // secondary text color
                error: '#FF0000', // error text color
            },
        },
    },
}

export default new Vuetify(opts)