import axios from 'axios'
import { ILLI_AUTH_SERVER_URL } from '../config'

export default {
    namespaced: true,
    state: {
        token: null,
        refresh_token: null,
        user: null,
    },

    getters: {
        authenticated (state) {
            return Boolean (state.token && state.user)
        },

        user (state) {
            return state.user
        },

        permission: (state) => (right) => {
            return Boolean (state.user.permissions?.[right])
        }
    },

    mutations: {
        SET_TOKEN (state, token) {
            state.token = token
        },
        SET_REFRESH_TOKEN (state, token) {
            state.refresh_token = token
        },
        SET_USER (state, user) {
            state.user = user
        }
    },
    actions: {
        async signIn ({ dispatch, commit }, credentials) {
            let response = await axios.post(`${ILLI_AUTH_SERVER_URL}/login`, credentials)
            if (response.data.refreshToken) {commit('SET_REFRESH_TOKEN', response.data.refreshToken)}
            return dispatch('attemptSetToken', response.data.accessToken)
        },

        async attemptSetToken ({ commit }, token) {
            if (token) {commit('SET_TOKEN', token)}

            if(!token) return
            try {
                let response = await axios.post(`${ILLI_AUTH_SERVER_URL}/authUser`)

                commit('SET_USER', response.data.user)
            } catch (e) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
            }
        },

        async getNewToken ({ dispatch, state }, refreshToken) {
            console.log("Getting new Token")
            console.log({token:(refreshToken) ? refreshToken : state.refreshToken})
            if(!refreshToken && !state.refreshToken) return false
            let response = await axios.post(`${ILLI_AUTH_SERVER_URL}/token`, {token:(refreshToken) ? refreshToken : state.refreshToken})
            if(!response.data.accessToken) return false;
            dispatch('attemptSetToken', response.data.accessToken)
            return response.data.accessToken
        },

        signOut ({ commit, state }) {
            return axios.delete(`${ILLI_AUTH_SERVER_URL}/logout/${state.refresh_token}`).then(() => {
                commit('SET_TOKEN', null)
                commit('SET_REFRESH_TOKEN', null)            
                commit('SET_USER', null)            
                commit('system/SET_SYSTEM', null, { root: true })            
            })
        }
    }

}