import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import en from './en.js';
import nl from './nl.js';

import store from '@/store'

Vue.use(VueRouter)

const routes = [
    ...en,
    ...nl
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
  
router.beforeEach((to, from, next) => {
    if (to.meta?.requiresAuth && !store.getters['auth/authenticated']) {
      return (to.name != 'home') ? next({ name: 'login', query: {from: to.fullPath} }) : next({ name: 'login' });
    }
    if (to.meta?.requiresPermission && !store.getters['auth/permission'](to.meta.requiresPermission)) {
      return next({ name: 'home' })
    }
    if (to.meta?.requiresSystem && !store.getters['system/system']) {
      return next({ name: 'systems' })
    }
    next()
});

export default router;
