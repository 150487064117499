//https://www.youtube.com/watch?v=oyZFerXujtU&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&index=12
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n';
import { ILLI_STREAM_API_SERVER_URL } from './config';

require('@/store/subscriber')

Vue.config.productionTip = false

axios.interceptors.response.use( (response) => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response?.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  console.log(error.config.url)
  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url.endsWith('/token') || error.config.url.endsWith('/login') || error.response.message == 'Account is disabled.') {
    console.log({endswith:error.config.url.endsWith('/token')})
    store.dispatch('auth/attemptSetToken', null)
    if(!error.config.url.endsWith('/login')) router.push({ name: 'home' });

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Try request again with new token
  return store.dispatch('auth/getNewToken', localStorage.getItem('refresh'))
    .then((token) => {
      console.log({newtoken:token})
      // New request with new token
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });

    })
    .catch((error) => {
      Promise.reject(error);
    });
});

store.commit('auth/SET_REFRESH_TOKEN', localStorage.getItem('refresh'))
store.dispatch('auth/attemptSetToken', localStorage.getItem('token'))
.then(() => { return store.dispatch('system/setSystem', localStorage.getItem('system'))})
.then(() => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})

// Standard stream server is live server
if ([null, 'null'].includes(localStorage.getItem('stream-server'))){
  localStorage.setItem('stream-server', ILLI_STREAM_API_SERVER_URL)
}