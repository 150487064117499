const SignIn = () => import(/* webpackChunkName: "SignIn"*/ '../views/SignIn.vue');
const Interests = () => import(/* webpackChunkName: "Interests"*/ '../views/Interests.vue');
const Systems = () => import(/* webpackChunkName: "Systems"*/ '../views/Systems.vue');
const SystemMenu = () => import(/* webpackChunkName: "SystemMenu"*/ '../views/SystemMenu.vue');
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword"*/ '../views/ResetPassword.vue');
const Selected = () => import(/* webpackChunkName: "Selected"*/ '../views/database/Selected.vue');
const Residents = () => import(/* webpackChunkName: "Residents"*/ '../views/Residents.vue');
const Announcements = () => import(/* webpackChunkName: "Announcements"*/ '../views/Announcements.vue');
const manageResidents = () => import(/* webpackChunkName: "manageResidents"*/ '../views/manageResidents.vue');
const manageResidentsInfo = () => import(/* webpackChunkName: "manageResidentsInfo"*/ '../views/manageResidentsInfo.vue');
const manageResidentsFiles = () => import(/* webpackChunkName: "manageResidentsFiles"*/ '../views/manageResidentsFiles.vue');
const manageFacilityFiles = () => import(/* webpackChunkName: "manageFacilityFiles"*/ '../views/manageFacilityFiles.vue');
const manageLinkedDevice = () => import(/* webpackChunkName: "manageLinkedDevice"*/ '../views/manageLinkedDevice.vue');
const RequestPasswordReset = () => import(/* webpackChunkName: "RequestPasswordReset"*/ '../views/RequestPasswordReset.vue');
const Api_key = () => import(/* webpackChunkName: "Api_key"*/ '../views/Api_key.vue');
const LinkDevice = () => import(/* webpackChunkName: "LinkDevice"*/ '../views/LinkDevice.vue');
const LinkedDevices = () => import(/* webpackChunkName: "LinkedDevices"*/ '../views/LinkedDevices.vue');
const privacyPolicy = () => import(/* webpackChunkName: "privacyPolicy"*/ '../views/PrivacyPolicy.vue');
const TagSystem = () => import(/* webpackChunkName: "TagSystem"*/ '../views/TagSystem.vue');
const Register = () => import(/* webpackChunkName: "Register"*/ '../views/Register.vue');
const ConfirmEmail = () => import(/* webpackChunkName: "ConfirmEmail"*/ '../views/ConfirmEmail.vue');

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  }, {
    path: '/selected',
    name: 'selected',
    component: Selected
  }, {
    path: '/manage_residents',
    name: 'manageResidents',
    component: manageResidents,
    meta: { requiresAuth: true, requiresPermission: "editResidents" }
  },{
    path: '/manage_residents_info',
    name: 'manageResidentsInfo',
    component: manageResidentsInfo,
    meta: { requiresAuth: true, requiresPermission: "editResidents" }
  },{
    path: '/manage_residents_files',
    name: 'manageResidentsFiles',
    component: manageResidentsFiles,
    meta: { requiresAuth: true, requiresPermission: "editResidents" }
  }, {
    path: '/residents',
    name: 'residents',
    component: Residents,
    meta: { requiresAuth: true, requiresPermission: "seeResidents" }
  }, {
    path: '/announcements/:id',
    name: 'announcementsId',
    component: Announcements,
    meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
  }, {
    path: '/announcements/new',
    name: 'announcementsNew',
    component: Announcements,
    meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
  }, {
    path: '/announcements',
    name: 'announcements',
    component: Announcements,
    meta: { requiresAuth: true, requiresPermission: "seeAnnouncements" }
  }, {
    path: '/requestPasswordReset',
    name: 'requestPasswordReset',
    component: RequestPasswordReset
  }, {
    path: '/resetPassword/:key',
    name: 'resetPassword',
    component: ResetPassword
  }, {
    path: '/interests',
    name: 'interests',
    component: Interests,
    meta: { requiresAuth: true, requiresPermission: "seeTags", requiresSystem: true }
  }, {
    path: '/systems/:id/files',
    name: 'manageFacilityFiles',
    component: manageFacilityFiles,
    meta: { requiresAuth: true, requiresPermission: "editSystems", requiresSystem: true }
  }, {
    path: '/systems',
    name: 'systems',
    component: Systems,
    meta: { requiresAuth: true, requiresPermission: "seeSystems" }
  }, {
    path: '/systemMenu',
    name: 'systemMenu',
    component: SystemMenu,
    meta: { requiresAuth: true, requiresPermission: "seeSystems" }
  }, {
    path: '/api_key',
    name: 'api_key',
    component: Api_key,
    meta: { requiresAuth: true, requiresPermission: "editSystems" }
  }, {
    path: '/link-device',
    name: 'linkDevice',
    component: LinkDevice,
    meta: { requiresAuth: true}
  }, {    
    path: '/link-device/code/:code',
    name: 'linkDeviceCode',
    component: LinkDevice,
    meta: { requiresAuth: true}
  }, {      
    path: '/link-device/stream/:stream',
    name: 'linkDeviceStream',
    component: LinkDevice,
    meta: { requiresAuth: true}
  }, {      
    path: '/linked-devices',
    name: 'linkedDevices',
    component: LinkedDevices,
    meta: { requiresAuth: true}
  }, {    
    path: '/linked-devices/:id',
    name: 'manageLinkedDevice',
    component: manageLinkedDevice,
    meta: { requiresAuth: true}
  }, {    
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: privacyPolicy,
    meta: { requiresAuth: false}
  },{
    path: '/tag-system',
    name: 'tagSystem',
    component: TagSystem,
    meta: { requiresAuth: true, requiresPermission: "editTags" }
  }, {
    path: '/register/:id?',
    name: 'register',
    component: Register,
    meta: { requiresAuth: false }
  }, {
    path: '/activate/:token',
    name: 'confirmEmail',
    component: ConfirmEmail,
    meta: { requiresAuth: false }
  }
]



export default routes
