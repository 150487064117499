<template>
  <v-app>
    <TheNavigation />
    <v-main>
      <v-container fluid>
        <router-view :key="refreshKey"/>
      </v-container>
    </v-main>
    <v-footer>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css");
</style>

<script>
import Vue from 'vue';
import TheNavigation from '@/components/TheNavigation';
import VuePageTitle from 'vue-page-title';
import './assets/global.css';
import './assets/fontawesome/css/all.min.css';
import { EventBus } from './utils/eventBus';

import VueFileAgent from 'vue-file-agent';

Vue.use(VueFileAgent);

export default Vue.extend({
  components: {
    TheNavigation
  },
  data() {
    return {
      refreshKey: 0
    };
  },
  created() {
    EventBus.$on('refresh', () => {
      this.refreshKey++;
    });
  }
})

Vue.use(VuePageTitle, {
  suffix: ' | Platform'
})


</script>